import { Link } from "gatsby";
import * as React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";
import "../styles/voorwaarden.scss";

const PrivacyVerklaring: React.FC<any> = (props) => {
    const { location } = props;

    const isSelected = (path: string) => {
        if (location.pathname.replace("/", "") === path) {
            return "voorwaarden-link-is-selected";
        }
        return ""
    }

    return (
        <>
            <SEO
                title="Privacy Statement - Leading Apps"
                description={"Leading App's privacy statement"}
                article={false}
            />

            <Header />

            <section id="doelgericht">

                <div className="section-spacer"></div>

                <div className="voorwaarden g-0 width-wrapper-medium mpb-sm">
                    <div className="col-md-10">

                        <div className="voorwaarden">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="mb-5">
                                        <h1 className="mb-4 bold"><b>Privacyverklaring</b></h1>
                                        <p className="italics grey mb-5">Versie 15 juni 2021</p>
                                        <p className="bold">Leading Apps, gevestigd aan Pasakker 73, 5712 HS te Someren,
                                            verwerkt persoonsgegevens in het kader van onze dienstverlening.</p>
                                        <p>Deze privacyverklaring is opgesteld om je uit te leggen hoe wij met persoonsgegevens omgaan.</p>

                                        De persoonsgegevens worden verwerkt door: <br />
                                        Leading Apps <br />
                                        Kloosterweg 39<br />
                                        5711 GJ Someren<br />
                                        (+31) 6 41 37 62 42<br />
                                    </div>

                                    <div className="mt-3 pt-3">
                                        <h2 className="bold mb-3 ">1. Persoonsgegevens die wij verwerken</h2>
                                        <p>Wij verwerken je persoonsgegevens doordat je gebruik maakt van onze
                                            diensten en/of omdat je deze gegevens zelf aan ons hebt verstrekt via
                                            bijvoorbeeld onze website, e-mail, telefoon of via de app.</p>

                                        <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
                                        <ul>
                                            <li>NAW-gegevens</li>
                                            <li>Contactgegevens zoals e-mailadressen en telefoonnummers</li>
                                            <li>KvK nummer</li>
                                            <li>Bankrekeningnummer</li>
                                            <li>BTW-nummer</li>
                                            <li> Inhoud van communicatie</li>
                                            <li>IP-adres en locatiegegevens</li>
                                            <li>Internetbrowser en apparaat type</li>
                                            <li>Gegevens over jouw activiteiten op onze website</li>
                                            <li>Overige persoonsgegevens die je actief verstrekt, bijvoorbeeld in correspondentie en / of
                                                telefonisch</li>
                                        </ul>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3">2. Doeleinden</h2>
                                        <p>Wij verwerken deze persoonsgegevens voor diverse doeleinden, zoals:</p>
                                        <ul>
                                            <li>Het onderhouden van contact</li>
                                            <li>Het leveren van een goede en efficiënte dienstverlening en het verbeteren hiervan</li>
                                            <li>Het verrichten van administratieve handelingen o.a. de facturatie van ons honorarium</li>
                                            <li>Marketing(o.a. het verzenden van onze nieuwsbrief)</li>
                                            <li>Nakoming van wettelijke verplichtingen</li>
                                            <li>Het kunnen aanbieden van gepersonaliseerde advertenties en informatie op de website</li>
                                        </ul>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">3. Bijzondere en / of gevoelige persoonsgegevens die wij verwerken</h2>
                                        <p>Onze website en / of dienst heeft niet de intentie gegevens te verzamelen
                                            over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of
                                            voogd.
                                            We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan
                                            betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er
                                            gegevens
                                            over kinderen verzameld worden zonder ouderlijke toestemming. Als je ervan overtuigd bent dat
                                            wij
                                            zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan
                                            contact met ons op via rowan@leadingapps.nl, dan verwijderen wij deze informatie.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">4. Grondslagen</h2>
                                        <p>Wij verwerken jouw persoonsgegevens voor de volgende doelen:</p>
                                        <ul>
                                            <li>Het afhandelen van jouw betaling</li>
                                            <li>Verzenden van onze nieuwsbrief en / of reclamefolder</li>
                                            <li>Je te kunnen bellen of e - mailen indien dit nodig is om onze dienstverlening uit te kunnen
                                                voeren</li>
                                            <li>Je te informeren over wijzigingen van onze diensten en producten</li>
                                            <li>Om goederen en diensten bij je af te leveren</li>
                                            <li>Wij analyseren jouw gedrag op de website om daarmee de website te verbeteren en het aanbod
                                                van producten en diensten af te stemmen op jouw voorkeuren.</li>
                                            <li>Wij verwerken ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens
                                                die wij nodig hebben voor onze belastingaangifte.</li>
                                        </ul>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">5. Geautomatiseerde besluitvorming</h2>
                                        <p>Wij nemen niet op basis van geautomatiseerde verwerkingen besluiten over
                                            zaken die(aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten
                                            die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens
                                            (bijvoorbeeld een medewerker van Leading Apps) tussen zit.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">6. Hoe lang we persoonsgegevens bewaren</h2>
                                        <p>Wij bewaren je persoonsgegevens niet langer dan strikt nodig is om de
                                            doelen te realiseren waarvoor je gegevens worden verzameld. Dit betekent dat de
                                            persoonsgegevens bewaard worden zolang dit nodig is om de betreffende doelen te bereiken.
                                            Bepaalde gegevens moeten langer bewaard worden omdat wij ons moeten houden aan wettelijke
                                            bewaarplichten, zoals de fiscale bewaarplicht.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">7. Delen van persoonsgegevens met derden</h2>
                                        <p>Wij delen jouw persoonsgegevens met verschillende derden als dit noodzakelijk
                                            is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke
                                            verplichting. Met bedrijven die je gegevens verwerken in onze opdracht, sluiten wij een
                                            verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid
                                            van jouw gegevens.</p>
                                        <p>Wij blijven verantwoordelijk voor deze verwerkingen.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">8. Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
                                        <p>Wij gebruiken functionele, analytische en tracking cookies. Een cookie is een klein
                                            tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser
                                            van je computer, tablet of smartphone. Wij gebruiken cookies met een puur technische
                                            functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld
                                            jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website
                                            goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die
                                            jouw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden.
                                        </p>
                                        <p>Bij jouw eerste bezoek aan onze website hebben wij je al geïnformeerd dat onze websites
                                            cookies gebruikt. Door onze website verder te bekijken ben je er tevens mee akkoord dat we
                                            cookies plaatsen op jouw computer. Je kunt je afmelden voor cookies door je internetbrowser
                                            zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die
                                            eerder is opgeslagen via de instellingen van je browser verwijderen.</p>
                                        <p>Op deze website kunnen ook cookies worden geplaatst door derden.
                                            Dit zijn bijvoorbeeld adverteerders en/of sociale media-bedrijven.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">9. Gegevens inzien, aanpassen of verwijderen</h2>
                                        <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen.
                                            Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te
                                            trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Leading Apps
                                            en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan
                                            indienen om de persoonsgegevens van jou, waarover wij van jou beschikken in een computerbestand
                                            naar jou of een ander, door jou genoemde organisatie, te sturen.</p>
                                        <p>Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging
                                            van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar
                                            op de verwerking van jouw persoonsgegevens sturen naar rowan@leadingapps.nl.</p>
                                        <p>Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een
                                            kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto,
                                            MRZ(machine readable zone, de strook met nummers onderaan het paspoort),
                                            paspoortnummer en Burgerservicenummer(BSN) zwart. Dit ter bescherming van je privacy.
                                            We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.</p>
                                        <p>Wij willen je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen
                                            bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende
                                            link:
                                            <span>https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</span>
                                        </p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">10. Hoe wij persoonsgegevens beveiligen</h2>
                                        <p>Wij nemen de bescherming van jouw gegevens serieus en we nemen passende maatregelen om misbruik,
                                            verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
                                            Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn
                                            van misbruik, neem dan contact op met ons via rowan@leadingapps.nl</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">11. Wijzigingen Privacyverklaring</h2>
                                        <p>Wij kunnen deze privacyverklaring aanpassen. Nieuwe versies worden altijd op de website
                                            gepubliceerd. Wij raden je daarom aan om deze verklaring geregeld te raadplegen,
                                            zodat je op de hoogte blijft van wijzigingen.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">12. Doorgifte van je gegevens buiten de EER</h2>
                                        <p>Je persoonsgegevens kunnen buiten de EER worden verwerkt.
                                            De EER is de Europese Economische Ruimte, bestaande uit de landen van de Europese Unie,
                                            Liechtenstein, Noorwegen en IJsland.
                                        </p>
                                        <p>Daarnaast kunnen je gegevens door derden buiten de EU worden opgeslagen wanneer
                                            gebruik wordt gemaakt van Google Analytics, LinkedIn of Facebook in het contact met jou.
                                            Deze partijen zijn “EU - VS Privacy Shield” gecertificeerd, zodat zij zich zullen houden aan
                                            de Europese privacyregelgeving.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2 mb-4 momt-2">
                        <div className="row g-0">
                            <Link to="/voorwaarden" >
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("voorwaarden")}>Algemene voorwaarden</h6>
                                </div>
                            </Link>
                            <Link to="/hosting-voorwaarden">
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("hosting-voorwaarden")}>Hosting voorwaarden</h6>
                                </div>
                            </Link>
                            <Link to="/privacy" className="voorwaarden-link-wrapper">
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("privacy")}>Privacyverklaring</h6>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </>
    )
}

export default PrivacyVerklaring;